.text {
   text-align: center;
   position: fixed;
   top: var(--sidebar-width);
   left: 0;
   bottom: 0;
   right: var(--sidebar-width);
   font-size: calc(20px + 1vmin);
   line-height: 50vh;
   background-color: rgba(0, 0, 0, 0.9);
   color: white;
   font-family: Roboto, sans-serif;
   letter-spacing: 3px;
   font-weight: 900;
   z-index: 100000;
}
.bullets {
   animation: fade 1s linear infinite;
}

.bullets:nth-child(2) {
   animation-delay: 0.25s;
}
.bullets:nth-child(3) {
   animation-delay: 0.5s;
}

@keyframes fade {
   to {
      opacity: 0;
   }
}
