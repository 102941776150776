* {
   box-sizing: border-box;
   background-repeat: no-repeat;
}

body {
   margin: 0;
   font-family: system-ui, -apple-system,
      /* Firefox supports this but not yet `system-ui` */ "Segoe UI", Roboto, Helvetica, Arial,
      sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   padding: 0;
   margin: 0;
   font-size: calc(12px + 1vmin);

   /* background-image: url(../public/bg.jpg); */
   /* background-color: #163432; */
   background-color: #1f3931;
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   background-attachment: fixed;
   color: rgba(224, 224, 224, 1);
}

body {
   background: #3f3f3f;
   background: url(./assets/bg.jpg);
   padding: 0;
   margin: 0;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

img {
   vertical-align: middle;
}

h1 {
   font-size: calc(1.1rem + 2vmin);
   margin-top: 0;
}

:root {
   --box-shadow: 0px 0px 20px 12px rgba(255, 255, 255, 0.15);
   --border: solid 3px #fff;
   --box-padding: 2vmin;
   --box-margin: 20px;
   --box-border-radius: 10px;
   --sidebar-width: 20vw;
}

.center {
   text-align: center;
}

a {
   color: #fff;
}

a:visited {
   color: #fff;
}

.react-responsive-modal-modal {
   background-color: #626262;
   color: black;
}
