.error-msg {
   border: 1px solid;
   margin: 10px 0px;
   padding: 15px 10px 15px 50px;
   background-repeat: no-repeat;
   background-position: 10px center;
}
.error-msg {
   color: #d8000c;
   background-color: #ffbaba;
   background-image: url("https://i.imgur.com/GnyDvKN.png");
}
