.links {
   display: flex;
   flex-direction: column;
   margin-bottom: 10px;
   align-items: flex-start;
}
.links a {
   display: inline-block;
   background-image: url(../../assets/active.png);
   padding: 0 32px;
   line-height: 28px;
   background-size: contain;
   margin: 4px 0;
}

.footer {
   position: absolute;
   bottom: 0;
   left: 0;
   width: calc(100% - var(--sidebar-width));
   padding: 10px;
   text-align: center;
}
