.wrap {
   height: 24px;
   border: 1px solid;
   position: relative;
   margin-top: calc(var(--box-padding) * 2);
}

.progress {
   height: 100%;
   background-color: #fff;
   box-shadow: 0 0 12px 8px rgb(255 255 255 / 20%);
   text-align: center;
   border: none;
   transition: 0.5s;
   background-size: 200% auto;
}
.content {
   position: absolute;
   width: 100%;
   left: 0;
   top: 50%;
   transform: translateY(-50%);
   background-color: transparent;
   text-align: center;
   font-size: 1.2rem;
   color: #ff3dff;
   text-shadow: 0 0 5px #ff3dff, 0 0 10px #ff3dff;
}
