.content {
   padding: 3vmin;
   margin: var(--box-margin);
   margin-right: calc(var(--box-margin) + 20vw);
   margin-top: var(--sidebar-width);
   -ms-overflow-style: none; /* Internet Explorer 10+ */
   scrollbar-width: none; /* Firefox */
}
.content::-webkit-scrollbar {
   display: none; /* Safari and Chrome */
}
