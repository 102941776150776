.wrap {
   display: flex;
   justify-content: center;
   align-items: center;
}

.wrap div {
   margin: 5px;
   border: 3px solid #fff;
   border-radius: 50%;
   width: 20%;
   height: 20%;
   display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
   padding: 3px;
   color: rgb(255, 255, 255);
   font-weight: 700;
}

.label {
   font-size: 0.6rem;
}

.digits {
   text-shadow: 0 0 1px #ffffff, 0 0 2px #ffffff;
}
