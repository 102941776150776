.wrap {
   padding: 10px;
   position: fixed;
   top: 0;
   left: 0;
   width: calc(100% - var(--sidebar-width));
   height: var(--sidebar-width);
   background-image: url(../../assets/bg-header2.jpg);
   background-position: center;
   background-size: cover;
   z-index: 1000;
   box-shadow: 0 2px 10px #000;
}
