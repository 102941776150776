.connect {
   margin-bottom: 2vh;
}
.address {
   padding: 2px 5px;
   background: rgb(2, 0, 36);
   background: radial-gradient(
      circle,
      rgba(2, 0, 36, 1) 0%,
      rgba(9, 9, 121, 1) 56%,
      rgb(5, 124, 147) 100%
   );
   border-radius: 10px;
}
