.basic-card {
   position: relative;
   border: var(--border);
   border-radius: var(--box-border-radius);
   box-shadow: var(--box-shadow);
   margin: 5px 0;
}

.basic-card .card-content {
   padding: 10px 20px;
}

.basic-card .card-content .label {
   padding-right: 5px;
}
.basic-card .card-content .value {
   font-weight: 600;
}

.basic-card .card-title {
   font-size: 25px;
   font-family: "Open Sans", sans-serif;
   padding: 0 10px;
}

.basic-card .card-text {
   line-height: 1.6;
}

.basic-card .card-link {
   width: -webkit-fill-available;
}

.status {
   width: 24px;
   vertical-align: top;
}
