.wrap {
   display: flex;
   flex-direction: row;
   justify-content: center;
   padding: 6px 3px;
   border: var(--border);
   border-radius: var(--box-border-radius);
   margin: 40px auto;
   max-width: 380px;
   box-shadow: var(--box-shadow);
}

.btn {
   margin-left: 8px;
   display: inline-block;
   margin: 5px 12px;
   text-align: center;
}
.btn img {
   width: calc(32px + 2vmin);
   vertical-align: bottom;
   border-radius: 50%;
}

.btn div {
   text-align: center;
   font-size: 0.9rem;
   white-space: nowrap;
}
