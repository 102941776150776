/* CSS */

.button {
   color: #8d40c3;
   border: 6px solid #8d40c3;
   background-color: transparent;
   border-radius: 15px;
   padding: 5px 15px;
   font-size: 1.2rem;
   font-family: tahoma;
   letter-spacing: 2px;
   cursor: pointer;
   font-weight: bold;
   filter: drop-shadow(0 0 10px #8d40c3) drop-shadow(0 0 30px #8d40c3) contrast(2) brightness(2);
   transition: 0.5s;
   text-transform: uppercase;
   margin: 15px;
}

.button:not([disabled]):focus {
   color: black;
   background-color: #db3eb1;
   filter: drop-shadow(0 0 20px #db3eb1) contrast(2) brightness(2);
}

.button:not([disabled]):hover {
   color: black;
   background-color: #db3eb1;
   filter: drop-shadow(0 0 20px #db3eb1) contrast(2) brightness(2);
}
