.newDeposit {
   border-radius: 10px;
   border: 3px solid #ffffff;
   box-shadow: var(--box-shadow);
   margin: var(--box-margin) 0;
   padding: 2vmin;
}

.container {
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-bottom: var(--box-margin);
}

.container label {
   position: relative;
}

.container label input {
   appearance: none;
   -webkit-appearance: none;
   cursor: pointer;
   margin: 0;
}

.container label span {
   position: relative;
   display: inline-block;
   width: calc(48px + 1vmin);
   height: calc(48px + 1vmin);
   background: #091921;
   line-height: calc(48px + 1vmin);
   text-align: center;
   margin: 0 4px;
   color: #6f6f6f;
   font-size: calc(14px + 1vmin);
   font-weight: 600;
   border-radius: 50%;
   cursor: pointer;
   box-shadow: -1px -1px 3px rgba(255, 255, 255, 0.1), 2px 2px 6px rgba(0, 0, 0, 0.8);
}

.container label span:hover {
   box-shadow: -1px -1px 3px rgba(255, 255, 255, 0.1), 2px 2px 6px rgba(0, 0, 0, 0.8),
      inset -2px -2px 10px rgba(255, 255, 255, 0.05), inset 2px 2px 10px rgba(0, 0, 0, 0.5);
}

.container label input:checked ~ span {
   color: #00fff1;
   box-shadow: inset -1px -1px 3px rgba(255, 255, 255, 0.1), inset 2px 2px 6px black;
   text-shadow: 0 0 5px #00fff1, 0 0 20px #00fff1;
}
