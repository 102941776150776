.wrap {
   display: flex;
   position: relative;
}
.check-left-line {
   border-left: 1px solid #565656;
   position: absolute;
   height: 63%;
   top: 30px;
   left: 8px;
   z-index: -1;
}
.checks {
   color: #565656;
   font-size: 20px;
   list-style: none;
   padding-left: 0;
}
.checks .active {
   color: aqua;
   text-shadow: var(--box-shadow);
}
.checks li {
   margin-bottom: 30px;
}
.checks li:before {
   content: "⬤";
   padding-right: 15px;
   z-index: 1;
}
