.planSpecs {
   --logowidth: 138px;
   padding: 10px;
   text-align: center;
   margin-top: calc(var(--logowidth) / 2 + 10px);
   padding-top: calc(var(--logowidth) / 2 + 10px);
   list-style: none;
   position: relative;
   border: var(--border);
   box-shadow: var(--box-shadow);
   border-radius: var(--box-border-radius);
}
.planSpecs::before {
   content: " ";
   position: absolute;
   width: var(--logowidth);
   height: var(--logowidth);
   top: 0;
   left: 50%;
   transform: translate(-50%, -50%);
   border: var(--border);
   border-radius: 50%;
   z-index: 1;
   background-size: contain;
}

.planSpecs.thor::before {
   background-image: url(../../assets/thor-icon.jpg);
}
.planSpecs.ironman::before {
   background-image: url(../../assets/ironman-icon.jpg);
}
.planSpecs.captain::before {
   background-image: url(../../assets/captain-icon.jpg);
}
.planSpecs.hulk::before {
   background-image: url(../../assets/hulk-icon.jpg);
}
.planSpecs.hawkeye::before {
   background-image: url(../../assets/hawkeye-icon.jpg);
}
.planSpecs.widow::before {
   background-image: url(../../assets/widow-icon.jpg);
}

.specsTitle {
   padding-right: 5px;
}
.specsValue {
   padding-left: 5px;
   font-weight: 900;
}
