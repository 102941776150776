.wrap {
   box-shadow: var(--box-shadow);
   border: var(--border);
   border-radius: var(--box-border-radius);
   padding: var(--box-padding);
   margin: var(--box-margin) 0;
}
.label {
   color: #fff;
   font-weight: 600;
   font-size: calc(14px + 2vmin);
}

.value {
   font-weight: 600;
   color: #fff;
   font-size: calc(16px + 2vmin);
   font-family: "Fredoka One", cursive;
   /* background-image: linear-gradient(#f7e064, #dc7b5a); */
   -webkit-background-clip: text;
   background-clip: text;
}
