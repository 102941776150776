.menuContainer {
   height: 100vh;
   overflow-y: scroll;
   width: var(--sidebar-width);
   background: #000;
   position: fixed;
   right: 0;
   top: 0;
   z-index: 10000;
   box-shadow: -2px 0 10px #000;
}
.menuContainer {
   -ms-overflow-style: none; /* Internet Explorer 10+ */
   scrollbar-width: none; /* Firefox */
}
.menuContainer::-webkit-scrollbar {
   display: none; /* Safari and Chrome */
}
.menuContainer div {
   width: 100%;
}
.menuContainer div ul {
   padding: 0;
   margin: 0;
}
.menuContainer div ul li {
   padding: 1em 0;
   width: 100%;
   height: var(--sidebar-width);
   text-align: center;
   display: block;
   color: #ece6c2;
   background-size: cover;
   background-position: left;
   background-repeat: no-repeat;
   position: relative;
}
.menuContainer div ul li:has(> a.active),
.menuContainer div ul li:hover {
   border-left: 3px solid #ece6c2;
   transition: all 0.2s ease-in;
}
.menuContainer div ul li:has(> a.active) a,
.menuContainer div ul li:hover a {
   color: #d96f57;
}
.menuContainer div ul li a {
   color: #ece6c2;
   text-decoration: none;
   font-family: Arial;
   display: inline-block;
   height: 100%;
   width: 100%;
}
.menuContainer div ul span {
   text-transform: uppercase;
   display: inline-block;
   height: 100%;
   position: absolute;
   top: 0;
   right: 0;
   font-weight: bold;
   writing-mode: vertical-rl;
   padding: 2px 0;
   background-color: #040830;
}
.menuContainer div ul span.month {
   font-size: calc(9px + 1vmin);
}
.home {
   background-image: url(../../assets/home-icon.jpg);
}
.thor {
   background-image: url(../../assets/thor-icon.jpg);
}
.ironman {
   background-image: url(../../assets/ironman-icon.jpg);
}
.hulk {
   background-image: url(../../assets/hulk-icon.jpg);
}
.widow {
   background-image: url(../../assets/widow-icon.jpg);
}

.captain {
   background-image: url(../../assets/captain-icon.jpg);
}
.hawkeye {
   background-image: url(../../assets/hawkeye-icon.jpg);
}
.message {
   background-image: url(../../assets/message-icon.jpg);
}
.help {
   background-image: url(../../assets/help-icon.jpg);
}
